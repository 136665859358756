import styled from 'styled-components';
import { theme } from '../theme/colors';
import '@fontsource/sora';
import { Field } from 'formik';

export const TextInput = styled(Field)`
	border: 2px solid ${theme.colors.border};
	background: none;
	border-radius: 8px;
	color: ${theme.colors.primaryText};
	text-decoration: none;
	font-family: 'Sora', sans-serif;
	font-weight: 500;
	font-size: 1rem;
	padding: 1rem 1.5rem;
	width: 15rem;
	/* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
	/* box-shadow: 0 1px 3px 0 #d4d9e1; */
	transition: all 200ms ease;
	margin-bottom: 2rem;
	cursor: pointer;
	margin-right: 1rem;

	&:focus {
		border-color: ${theme.colors.primary};
	}

	@media (max-width: 750px) {
		width: 100%;
		margin: 1rem 0;
	}
`;
