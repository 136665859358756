import { Form } from 'formik';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 2rem;
`;
