import styled from 'styled-components';
import { theme } from '../theme/colors';
import '@fontsource/sora';

export const Button = styled.button`
	border: none;
	background: none;
	border-radius: 6px;
	background-color: ${theme.colors.primary};
	color: ${theme.colors.buttonText};
	text-decoration: none;
	font-family: 'Sora', sans-serif;
	font-weight: 600;
	font-size: 1rem;
	padding: 1rem 1.5rem;
	/* box-shadow: 0 1px 3px 0 #d4d9e1; */
	transition: all 200ms ease;
	margin-bottom: 2rem;
	cursor: pointer;

	&:hover {
		background-color: #ff325b9a;
	}

	@media (max-width: 750px) {
		width: 100%;
	}
`;
