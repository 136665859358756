import React from 'react';
import { BigTitle } from '../components/BigTitle';
import { CenteredDiv } from '../components/Layout/CenteredDiv';
import Footer from '../components/Footer';
import { Button } from '../components/Button';
import Logo from '../components/Logo';
import { Text } from '../components/Text';
import { TextInput } from '../components/TextInput';
import { StyledForm } from '../components/Form';
import { Helmet } from 'react-helmet';
import '../index.css';
import Layout from '../components/Layout/Layout';
import { navigate } from 'gatsby';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { theme } from '../theme/colors';
import { Widget, PopupButton } from '@typeform/embed-react';

const StyledPopupButton = styled(PopupButton)`
	margin: 2rem 0;
	border: none;
	background: none;
	border-radius: 6px;
	background-color: ${theme.colors.primary};
	color: ${theme.colors.buttonText};
	text-decoration: none;
	font-family: 'Sora', sans-serif;
	font-weight: 600;
	font-size: 1rem;
	padding: 1rem 1.5rem;
	transition: all 200ms ease;
	margin-bottom: 2rem;
	cursor: pointer;

	&:hover {
		background-color: #ff325b9a;
	}

	@media (max-width: 750px) {
		width: 100%;
	}
`;

export const ErrorMessage = styled.p`
	color: ${theme.colors.error};
	font-size: 0.8rem;
	position: absolute;
	bottom: -32px;
	left: 5px;
`;

function encode(data) {
	return Object.keys(data)
		.map(
			(key) =>
				encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
		)
		.join('&');
}

const Contact = () => {
	const initialValues = {
		email: '',
	};

	const validationSchema = Yup.object({
		email: Yup.string().email().required('Please enter your email'),
	});

	return (
		<Layout>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Join The Waitlist! | StudyFlow</title>
			</Helmet>
			<CenteredDiv
				style={{
					minHeight: '90vh',
				}}>
				<Logo />
				<BigTitle style={{ marginTop: '4rem', maxWidth: '40rem' }}>
					Hello! You caught us before we're ready
				</BigTitle>
				<Text>
					We're working hard to put the finishing touches onto
					StudyFlow. Things are going well and it should be ready to
					help you master your learning very soon. If you'd like us to
					send you a reminder when we're ready, just follow the steps
					below!
				</Text>
				<StyledPopupButton id='xHGepTlP'>
					Join the waitlist!
				</StyledPopupButton>
				{/* <Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={async (
						values,
						{ setSubmitting, setErrors, resetForm }
					) => {
						try {
							fetch('/?no-cache=1', {
								method: 'POST',
								headers: {
									'Content-Type':
										'application/x-www-form-urlencoded',
								},
								body: encode({
									'form-name': 'earlyaccess',
									...values,
								}),
							});
							resetForm();
							navigate('/thank-you/');
						} catch (error) {
							setErrors(error);
						} finally {
							setSubmitting(false);
						}
					}}>
					{({
						errors,
						touched,
						values,
						handleChange,
						isSubmitting,
						isValid,
						dirty,
						handleReset,
					}) => (
						<StyledForm
							name='earlyaccess'
							onReset={handleReset}
							data-netlify='true'
							data-netlify-honeypot='bot-field'>
							<TextInput
								label='Email'
								placeholder='you@website.com'
								type='text'
								name='email'
								value={values.email}
								onChange={handleChange}
							/>
							<Button
								disabled={isSubmitting || !isValid || !dirty}
								type='submit'>
								Remind me
							</Button>
							{errors.email && touched.email ? (
								<ErrorMessage>{errors.email}</ErrorMessage>
							) : null}
						</StyledForm>
					)}
				</Formik> */}
			</CenteredDiv>
			<Footer />
		</Layout>
	);
};

export default Contact;
