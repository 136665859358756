import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme/colors';

const Container = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${theme.colors.background};
`;

export default function Layout({ children }) {
	return <Container>{children}</Container>;
}
